import onLoadAsync from '../shared/on_load_async';

onLoadAsync(() => {
  document.querySelectorAll('.js-account').forEach((form) => {
    const checkbox = form.querySelector('[type=checkbox]');
    const submit = form.querySelector('[type=submit]');
    checkbox.addEventListener('change', () => {
      submit.disabled = !checkbox.checked;
    });
  });
});
