import onLoadAsync from '../shared/on_load_async';

onLoadAsync(() => {
  document.querySelectorAll('.js-password-field').forEach((passwordField) => {
    const passwordInput = passwordField.querySelector('.js_password_input');
    const showPasswordIcon = passwordField.querySelector('.js_show_password');
    showPasswordIcon.addEventListener('click', () => {
      if (passwordInput.type === 'password') {
        passwordInput.type = 'text';
      } else {
        passwordInput.type = 'password';
      }
    });
  });
});
