import onLoadAsync from '../shared/on_load_async';

onLoadAsync(() => {
  document.querySelectorAll('#modal-site_selector .radio_button').forEach((site) => {
    site.addEventListener('click', () => {
      // eslint-disable-next-line no-param-reassign
      site.querySelector('input').checked = true;
    });
  });
});
