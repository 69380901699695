/* eslint no-console:0 */
const Rails = require('@rails/ujs');

Rails.start();

require('../stylesheets/ecppp/application.sass');

require('../javascript/shared/components/accordion');
require('../javascript/shared/components/checkbox_for_button');
require('../javascript/shared/components/dropdown');
require('../javascript/shared/components/burger_menu');
require('../javascript/shared/components/modal');
require('../javascript/shared/slider');
require('../javascript/shared/klaro-config');
require('../javascript/shared/stimulus');

require('../javascript/ecs/date_input_select');
require('../javascript/ecs/loader');
require('../javascript/ecs/login');
require('../javascript/ecs/password_field');
require('../javascript/ecs/file-downloader');

require('../javascript/ecppp/account');
require('../javascript/ecppp/parrainage');
require('../javascript/ecppp/site_selector');
require('../javascript/ecppp/mon_suivi_conso.js');
require('../javascript/ecppp/resiliation.js');
require('../javascript/ecppp/tracking_events.js');
